"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./../scss/main");
var Application = /** @class */ (function () {
    function Application() {
    }
    Application.prototype.run = function () {
    };
    return Application;
}());
document.addEventListener("DOMContentLoaded", function (event) {
    var application = new Application();
    application.run();
});
